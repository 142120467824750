<template>
  <div :key="location.uuid">
    <v-card outlined>
      <v-toolbar flat>
        <v-toolbar-title>{{ location.label }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          flat
          class="primary mr-3"
          @click="addZone"
        >
          Add Zone
        </v-btn>
        <v-btn
          flat
          class="primary"
          @click="addTable"
        >
          Add Table
        </v-btn>
      </v-toolbar>
      <v-card-subtitle>{{ location.uuid }}</v-card-subtitle>
      <v-card-subtitle>{{ location.description }}</v-card-subtitle>
      <v-tabs v-model="selectedTab">
        <template v-for="tab in tabs">
          <v-tab
            :key="tab.key"
            :href="'#' + tab.key"
          >
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
    </v-card>
    <v-tabs-items
      class="grey lighten-3 transparent"
      v-model="selectedTab"
      show-arrows
    >
      <template v-for="tab in tabs">
        <v-tab-item
          class="transparent"
          :key="tab.key"
          :value="tab.key"
        >
          <LocationForm
            v-if="!tab.isZone && tab.key === 'form'"
            :location="location"
          />
          <LocationVariationList
            v-if="tab.isZone"
            :location="location"
            :zone="tab.key"
            :variations="variationsInZone(tab.key)"
            :key="tab.key"
          />
          <v-card outlined>
            <BlockedItems
              v-if="tab.key === 'blocked-items'"
              :location="location"
            />
          </v-card>
          <BlockedItemVariationsList
            v-if="tab.key === 'blocked-variations'"
            :location="location"
          />
        </v-tab-item>
      </template>
    </v-tabs-items>
  </div>
</template>

<script>

import LocationForm from '@/components/LocationForm'
import LocationVariationList from '@/components/LocationVariationList'
import BlockedItems from '@/components/item/BlockedItemsList'
import BlockedItemVariationsList from "@/components/item/BlockedItemVariationsList"

export default {
    name: 'LocationProfile',
    props: ['location'],
    components: {
      	BlockedItemVariationsList,
        LocationForm,
        LocationVariationList,
				BlockedItems
    },
    data() {
        return {
            selectedTab: 'form'
        }
    },
    methods: {

				addTable() {
					this.$store.commit('updateGenericDialog', {
            'component': 'components/location/LocationVariationDialog',
            'props': {
              locationUUID: this.location.uuid,
							value: true
            }
					})
				},

        addZone() {
          this.$store.commit('updateGenericDialog', {
            'component': 'components/zone/ZoneForm',
            'props': {
              'location': this.location,
            }
					})
        },
        
        variationsInZone: function(zoneUUID) {
					if(this.variations === null) {
							return []
					}

					let variationArray =  this.variations.filter(variation => variation && variation.zone !== null && variation.zone !== undefined && variation.zone.uuid === zoneUUID)
					variationArray.sort(function(left, right) {
						if (left.sorting_weight === null) {
							left.sorting_weight = 0
						}
						if (right.sorting_weight === null) {
							right.sorting_weight = 0
						}
						return left.sorting_weight < right.sorting_weight ? -1 : 1
					})
					return variationArray
      }
    },
    computed: {
        variations() {
            if(this.location === null) {
                return []
            } else {
                return this.location.variations
            }
        },
        zones() {
            const zoneIndex = []
            const zones = []

            for(let i in this.location.variations) {
							const variation = this.location.variations[i]

							if(variation && variation.zone && !zoneIndex.includes(variation.zone.uuid)) {
									zoneIndex.push(variation.zone.uuid)
									zones.push(variation.zone)
							}

            }

            return zones
      },
      locationHasTables() {
        return this.location && Array.isArray(this.location.variations) && this.location.variations.length > 0
      },
      tabs() {

        const tabs = []

        tabs.push({
            title: this.$t('form'),
            key: 'form',
            isZone: false
        })

        tabs.push({
            title: this.$t('blockedItems'),
            key: 'blocked-items',
            isZone: false
        })

        tabs.push({
          title: this.$t('blockedVariations'),
          key: 'blocked-variations',
          isZone: false
        })

        if(this.zones !== null && this.zones !== undefined && this.zones.length > 0) {

          for(let tabIndex in this.zones) {
            if(this.zones[tabIndex]) {
              tabs.push({
                title: this.zones[tabIndex].name,
                key: this.zones[tabIndex].uuid,
                isZone: true
              })
            }
          }
        }

        return tabs
      }
    }
}
</script>
