<template>
  <v-list-item
    link
    @click="clickedOnVariationCombination"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ locationVariationCombination.label }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-content>
      <div class="text-left">
        <v-chip
          v-for="(variation, index) in locationVariationCombination.locationVariations"
          :key="variation.uuid + '-' + index"
          class="mx-1"
        >
          {{ variation.label }}
        </v-chip>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    name: "LocationVariationCombinationListItem",
    props: {
      locationVariationCombination: {
        type: Object,
        required: true
      }
    },
    methods: {
      clickedOnVariationCombination() {
        this.$emit('select', this.locationVariationCombination)
      }
    }
  }
</script>

<style scoped>

</style>
