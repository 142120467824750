<template>
  <v-list-item
    :disabled="unblocking"
    @click="showDialog = true"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ variation.label }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-chip
        small
        class="red white--text"
        style="font-size: 14px;"
      >
        {{ $t('blocked') }}
      </v-chip>
    </v-list-item-action>
    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('confirm') }}
        </v-card-title>
        <v-card-text>
          {{ $t('pleaseConfirmAction') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDialog = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="unblock"
            :loading="unblocking"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: "BlockedItemVariationListItem",
  props: {
    variationBlock: {
      type: Object,
      default: () => ({})
    },
    location: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      unblocking: false,
      showDialog: false
    }
  },
  computed: {
    variation() {
      const variations = this.$store.state.variations
      if(Array.isArray(variations) && typeof this.variationBlock !== 'undefined' && this.variationBlock !== null) {
        return variations.find(variation => variation && variation.uuid === this.variationBlock.variation)
      }
      return null
    }
  },
  methods: {
    unblock() {
      this.unblocking = true
      this.$store.dispatch('unblockVariationForLocation', { variation: this.variation, location: this.location }).then(result => {
        if(result) {
          this.$emit('updated')
          this.$store.commit('updateActionSuccess', { message: this.$t('variationBlockRemoved') })
          this.showDialog = false
        }
      }).finally(() => {
        this.unblocking = false
      })
    }
  }
}
</script>

<style scoped>

</style>