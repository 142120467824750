<template>
  <v-card outlined>
    <v-progress-linear
      v-if="gettingVariationBlocks"
      indeterminate
    />
    <v-list v-if="Array.isArray(variationBlocks) && variationBlocks.length > 0">
      <template v-for="block in variationBlocks">
        <BlockedItemVariationListItem
          :key="block.variation"
          :variation-block="block"
          :location="location"
          @updated="getVariationBlocksForLocation"
        />
      </template>
    </v-list>
    <div
      v-else-if="!gettingVariationBlocks"
      class="pa-4"
    >
      {{ $t('noBlocksFound') }}
    </div>
  </v-card>
</template>

<script>
import BlockedItemVariationListItem from "@/components/item/BlockedItemVariationListItem"
export default {
  name: "BlockedItemVariationsList",
  components: {BlockedItemVariationListItem},
  props: {
    location: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      variationBlocks: null,
      gettingVariationBlocks: false
    }
  },
  methods: {
    getVariationBlocksForLocation() {
      this.gettingVariationBlocks = true

      this.$store.dispatch('getVariationLocationBlocks', this.location).then(result => {
        this.variationBlocks = result
      }).finally(() => {
        this.gettingVariationBlocks = false
      })
    }
  },
  mounted() {
    this.getVariationBlocksForLocation()
  }
}
</script>

<style scoped>

</style>