<template>
  <v-list-item
    link
    @click="selected"
  >
    <v-list-item-title>{{ etag.tagID }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
    name: 'EtagListItem',
    props: {
      etag: {
        type: Object, 
        default: () => {}
      }
    },
    methods: {
        selected() {
            this.$emit('selected', this.etag)
        }
    }
}
</script>