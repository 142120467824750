<template>
  <v-dialog
    max-width="800"
    value="true"
    @click:outside="clickOutside"
    :persistent="processing"
    scrollable
  >
    <v-card>
      <v-card-title>{{ $t('etag') }}</v-card-title>
      <v-card-text class="pa-0">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <EtagForm
              class="pl-6 pr-6"
              v-model="value"
              :location="location"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div
              class="mr-5 mb-5"
              :class="$vuetify.breakpoint.smAndDown ? 'ml-5' : ''"
            >
              <DateSelection
                outlined
                hide-details
                no-icon
                :label="$t('previewDate')"
                :disabled="processing"
                clearable
                v-model="previewDate"
                no-current-day-preload
              />
            </div>
            <div
              class="mr-5 mb-5"
              :class="$vuetify.breakpoint.smAndDown ? 'ml-5' : ''"
            >
              <TimeSelection
                outlined
                hide-details
                no-icon
                :label="$t('previewTime')"
                :disabled="processing"
                clearable
                v-model="previewTime"
                update-on-button-click
              />
            </div>
            <div
              class="mr-5"
              :class="$vuetify.breakpoint.smAndDown ? 'ml-5' : ''"
            >
              <iframe
                :title="$t('etagPreview')"
                :src="propertyURL"
                width="100%"
                style="border: 1px solid #ccc"
                height="600px"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="processing"
          color="error"
          @click="clickOutside"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="processing"
          :loading="deleting"
          text
          v-if="op === 'update'"
          color="red"
          @click="showConfirmDelete = !showConfirmDelete"
        >
          {{ $t('delete') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="processing"
          :loading="creating"
          v-if="op === 'create'"
          color="primary"
          @click="create"
        >
          {{ $t('create') }}
        </v-btn>
        <v-btn
          :disabled="processing"
          :loading="updating"
          v-if="op === 'update'"
          color="primary"
          @click="update"
        >
          {{ $t('update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      max-width="500"
      v-model="showConfirmDelete"
      v-if="showConfirmDelete"
    >
      <v-card>
        <v-card-title>
          {{ $t('areYouSure?') }}
        </v-card-title>
        <v-card-text class="text-justify pb-0 pt-2">
          <v-alert>{{ $t('pleaseConfirmThisActionAboutToRemoveEtag') }}{{ value.tagID }} {{ $t('fromThisZoneLocation') }}</v-alert>
          <v-alert type="info">
            {{ $t('pleaseNoteEtagsAndSettingsRemovedAndNotRecoverable') }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-row
            dense
            justify="space-between"
          >
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                block
                color="primary"
                large
                @click="showConfirmDelete = !showConfirmDelete"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                block
                color="error"
                large
                @click="remove"
              >
                {{ $t('confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>

import EtagForm from "./EtagForm"
import DateSelection from "@/components/common/DateSelection"
import TimeSelection from "@/components/common/TimeSelection"

export default {
  name: 'EtagDialog',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    location: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    TimeSelection,
    DateSelection,
    EtagForm
  },
  data: () => ({
    op: null,
    processing: false,
    creating: false,
    updating: false,
    deleting: false,
    showConfirmDelete: false,
    previewDate: null,
    previewTime: null
  }),
  methods: {
    clickOutside() {
      if(!this.processing) {
        this.$emit('closed')
      }
    },
    remove() {
      this.showConfirmDelete = false
      this.processing = true
      this.deleting = true
      this.$store.dispatch('releaseEtag', this.value).then(result => {
        if(result) {
          this.$emit('released')
        }
      }).finally(() => {
        this.processing = false
        this.deleting = false
      })
    },
    create() {
      this.processing = true
      this.creating = true
      this.removePreviewTimestamp()
      const newEtag = this.value
      const newEtagPropertyURL = new URL(newEtag.property)
      newEtagPropertyURL.searchParams.delete('admin')
      newEtag.property = newEtagPropertyURL.toString()
      this.$store.dispatch('claimEtag', newEtag).then(etag => {
        if(etag !== null) {
          this.$emit('claimed')
        }
      }).finally(() => {
        this.processing = false
        this.creating = false
      })
    },
    update() {
      this.processing = true
      this.updating = true
      this.removePreviewTimestamp()
      const updatedEtag = this.value
      const updatedEtagPropertyURL = new URL(updatedEtag.property)
      updatedEtagPropertyURL.searchParams.delete('admin')
      updatedEtag.property = updatedEtagPropertyURL.toString()
      this.$store.dispatch('updateEtag', updatedEtag).then(etag => {
        if(etag) {
          this.$emit('updated')
        }
      }).finally(() => {
        this.processing = false
        this.updating = false
      })
    },
    removePreviewTimestamp() {
      this.parsedPropertyURL.searchParams.delete('previewTimestamp')
      this.parsedPropertyURL.searchParams.delete('admin')
      this.$emit('updateProperty', this.parsedPropertyURL.toString())
    },
    setPreviewTimestamp(value) {
      this.parsedPropertyURL.searchParams.set('previewTimestamp', value)
      this.$emit('updateProperty', this.parsedPropertyURL.toString())
    }
  },
  computed: {
    propertyURL() {
      const url = new URL(this.value.property)
      url.searchParams.set('admin', String(new Date().getTime()))
      return url.toString()
    },
    parsedPropertyURL() {
      return new URL(this.propertyURL)
    },
    propertyURLHasPreviewTimestamp() {
      return !!this.parsedPropertyURL.searchParams.get('previewTimestamp')
    },
    previewTimestamp: {
      get() {
        const timestamp = this.parsedPropertyURL.searchParams.get('previewTimestamp')
        if(this.propertyURL !== null && this.propertyURL !== undefined && timestamp === undefined) {
          return this.today
        }
        if(timestamp === null) {
          return null
        }
        return timestamp * 1
      },
      set(value) {
        if(value === 0 || value === null || value === undefined) {
          this.removePreviewTimestamp()
        } else {
          this.setPreviewTimestamp(value)
        }
      }
    },
    today() {
      const systemTime = this.$store.getters.systemTime
      let date = new Date()
      if(systemTime) {
        date = new Date(systemTime)
      }
      return date.getTime()
    }
  },
  watch: {
    previewTime(value) {
      let dateWithTime = null
      if(value !== null && value !== undefined) {
        // Selected Time
        let hour = 0
        let minute = 0
        if(value.includes(':') && value.length === 5) {
          const split = value.split(':')
          hour = split[0] * 1
          minute = split[1] * 1
        } else if(value.length === 4) {
          hour = value.substr(0,2) * 1
          minute = value.substr(2, 2) * 1
        }

        let timestamp = this.today
        // If preview date has been selected
        if(this.previewDate !== null && this.previewDate !== undefined && this.previewDate !== 0) {
          timestamp = this.previewDate
        }

        dateWithTime = new Date(timestamp).setUTCHours(hour, minute, 0, 0)
      } else {
        // Current Time or Empty
        if(this.previewDate !== null && this.previewDate !== undefined && this.previewDate !== 0) {
          const date = new Date(this.today)
          const hour = date.getUTCHours()
          const minute = date.getUTCMinutes()

          dateWithTime = new Date(this.previewDate).setUTCHours(hour, minute, 0, 0)
        }
      }

      this.previewTimestamp = dateWithTime
    },
    previewDate(value) {
      let dateWithTime = null
      if(value !== null && value !== undefined) {
        if(this.previewTime !== undefined && this.previewTime !== null && this.previewTime !== 0 && this.previewTime !== '0') {
          // Selected Time
          let hour = 0
          let minute = 0
          if(this.previewTime.includes(':') && this.previewTime.length === 5) {
            const split = this.previewTime.split(':')
            hour = split[0] * 1
            minute = split[1] * 1
          } else if(this.previewTime.length === 4) {
            hour = this.previewTime.substr(0,2) * 1
            minute = this.previewTime.substr(2, 2) * 1
          }

          dateWithTime = new Date(value).setUTCHours(hour, minute, 0, 0)
        } else {
          dateWithTime = new Date(value).getTime()
        }
      }
      this.previewTimestamp = dateWithTime
    }
  },
  beforeMount() {
    this.op = this.value.tagID === null ? 'create' : 'update'
    this.$store.dispatch('getCurrentSystemTime')
  },
  mounted() {
    this.removePreviewTimestamp()
  }
}
</script>
