<template>
  <v-list-item @click="$emit('click')">
    <v-list-item-content>
      <v-list-item-title>{{ location.label }}</v-list-item-title>
      <v-list-item-subtitle>{{ location.description }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    name: 'LocationListItem',
    props: {
      location: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({

    }),
  }
</script>
