<template>
  <div>
    <v-dialog v-model="locationVariationCombinationDialog">
      <LocationVariationCombinations :location="location" />
    </v-dialog>
    <div v-if="variations !== null">
      <v-container fluid>
        <v-row align="center">
          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="addLocationVariation"
            >
              {{ $t('add') }}
            </v-btn>
            <v-btn
              color="primary"
              class="ml-2"
              @click="editCombinations"
            >
              {{ $t('combinations') }}
            </v-btn>
            <v-btn
              color="primary"
              class="ml-2"
              @click="editLayout"
            >
              {{ $t('layout') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-list>
        <v-divider />
        <v-list-item>
          <v-list-item-action class="ml-0 mr-2">
            <v-row
              role="button"
              dense
            >
              <v-col class="pr-0">
                <v-checkbox
                  v-model="selectedLines"
                  :indeterminate="checkboxIsIndeterminate"
                />
              </v-col>
              <v-col class="pl-0">
                <v-menu
                  v-model="selectAllMenu"
                  close-on-content-click
                  offset-y
                  :disabled="processing"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="selectAll">
                      <v-list-item-action>
                        <v-icon>mdi-checkbox-multiple-marked</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('all') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="selectNone">
                      <v-list-item-action>
                        <v-icon>mdi-checkbox-blank-outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('none') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-list-item-action>
          <v-divider vertical />
          <v-list-item-action
            v-if="selectedForBulk && selectedForBulk.length"
            class="ml-2 mr-0"
          >
            <v-tooltip
              bottom
              nudge-right="55"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editMultiple"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!zoneHasEtags"
                >
                  <v-icon>mdi-tag-multiple</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('editMultipleEtags') }}</span>
            </v-tooltip>
          </v-list-item-action>
          <v-list-item-action
            v-if="selectedForBulk && selectedForBulk.length"
            class="mx-0"
          >
            <v-tooltip
              bottom
              nudge-right="55"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="createMultiple"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-tag-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('AddMultipleEtags') }}</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
      </v-list>
      <v-card
        color="grey lighten-2"
        flat
        v-if="selectedForBulk && selectedForBulk.length"
        class="mx-4"
      >
        <v-row justify="center">
          <v-col cols="auto">
            <span v-html="textSelectedForBulk" />
          </v-col>
        </v-row>
      </v-card>
      <v-list>
        <template v-for="(variation, index) in variations">
          <LocationVariationListItem
            :key="variation.uuid"
            :variation="variation"
            :location="location"
            :bulk-selected="variation.selectedForBulk"
            @selectForBulk="variationBulkSelected"
          />
          <v-divider
            :key="index"
            inset
          />
        </template>
      </v-list>
    </div>
    <EtagBulkDialog
      v-model="showBulkDialog"
      v-if="showBulkDialog"
      :selected-items="selectedForBulk"
      @closed="closedDialog"
      @bulkUpdated="bulkUpdated"
      @bulkClaimed="bulkClaimed"
      @bulkReleased="bulkReleased"
      :add="bulkCreate"
      :location="location"
    />
    <LocationVariationDialog
      mode="create"
      v-if="showLocationVariationDialog"
      :location-u-u-i-d="location.uuid"
      v-model="showLocationVariationDialog"
      @closed="closeLocationVariationDialog"
    />
  </div>
</template>

<script>

import LocationVariationListItem from "./LocationVariationListItem"
import EtagBulkDialog from "@/components/etag/EtagBulkDialog"
import LocationVariationCombinations from "@/components/location/LocationVariationCombinations"
import LocationVariationDialog from "@/components/location/LocationVariationDialog"

export default {
	name: 'LocationVariationList',
  props: {
    variations: {
      type: Array,
      default: () => []
    },
    zone: {
      type: String,
      default: ''
    },
    location: {
      type: Object,
      default: () => {}
    }
  },
	components: {
		EtagBulkDialog,
		LocationVariationListItem,
    LocationVariationCombinations,
    LocationVariationDialog
	},
	data() {
		return {
			bulkEnabled: false,
			selectedForBulk: [],
			showBulkDialog: false,
			processing: false,
			selectAllMenu: false,
			selectedLines: false,
			zoneName: null,
			zoneNameIsReadOnly: true,
			bulkCreate: false,
      locationVariationCombinationDialog: false,
      showLocationVariationDialog: false
		}
	},
	mounted() {
		this.zoneName = this.currentZoneName
	},
	watch: {
		selectedLines(value) {
			if(value === true) {
				this.variations.forEach(variation => {
					variation.selectedForBulk = true
					this.selectedForBulk.push(variation)
				})
			} else {
				this.variations.forEach(variation => {
					variation.selectedForBulk = false
					this.selectedForBulk = []
				})
			}
		}
	},
	methods: {
    closeLocationVariationDialog() {
      this.showLocationVariationDialog = false
      this.$store.dispatch('getInitialData')
    },
    addLocationVariation() {
      this.showLocationVariationDialog = true
    },
    editCombinations() {
      this.locationVariationCombinationDialog = true
    },
    editLayout() {

    },
		bulkReleased(results) {
			this.closedDialog()
			this.selectedLines = false

      let message = this.$t('success')
      let subMessage = this.$t('successfulRemoval')

			if(results.successfulResults) {
        message = this.$t('successfullyRemovedNumberEtags', {number: results.successfulResults} )
			}

			if(results.unsuccessfulResults && results.unsuccessfulResults.length) {
        subMessage = this.$t('thereWereNumberEtagsNotRemoved', {number: results.unsuccessfulResults.length})
			}
      this.$store.commit('updateActionSuccess', {
        message: message,
        subMessage: subMessage
      })
		},
		bulkUpdated(results) {
			this.closedDialog()
			this.selectedLines = false
			let message = ''
			let subMessage = ''
			if(results.successfulResults) {
        message += this.$t('successfullyUpdatedNumberEtags', {number: results.successfulResults})
			}
			if(results.unsuccessfulResults && results.unsuccessfulResults.length) {
        subMessage += this.$t('thereWereNumberEtagsNotUpdated', {number: results.unsuccessfulResults.length})
			}
      this.$store.commit('updateActionSuccess', {
        message: message,
        subMessage: subMessage
      })
		},
		bulkClaimed(results) {
			this.closedDialog()
			this.selectedLines = false

      let message = this.$t('success')
      let subMessage = this.$t('successfullyAddedEtags')
      if(results.successfulResults) {
        message = this.$t('successfullyAddedNumberEtags', {number: results.successfulResults})
			}
			if(results.unsuccessfulResults && results.unsuccessfulResults.length) {
        subMessage = this.$t('thereWereNumberEtagsWeCouldNotAdd', {number: results.unsuccessfulResults.length})
			}
      this.$store.commit('updateActionSuccess', {
        message: message,
        subMessage: subMessage
      })
		},
		selectAll() {
			this.selectedLines = true
		},
		selectNone() {
			this.selectedLines = false
		},
		editMultiple() {
			this.bulkCreate = false
			this.selectedForBulk = this.selectedForBulk.filter(etag => this.variationEtags(etag) && this.variationEtags(etag).length)
			this.showBulkDialog = !this.showBulkDialog
		},
		createMultiple() {
			this.bulkCreate = true
			this.showBulkDialog = !this.showBulkDialog
		},
		closedDialog() {
			this.showBulkDialog = !this.showBulkDialog
		},
		getSelectedForBulk() {
			return this.variations.filter(variation => variation.selectedForBulk)
		},
		variationBulkSelected(variation) {
			variation.selectedForBulk = !variation.selectedForBulk
			if(variation.selectedForBulk) {
				this.selectedForBulk.push(variation)
			} else {
				const index = this.selectedForBulk.indexOf(variation)
				this.selectedForBulk.splice(index, 1)
			}
			this.selectedForBulk = this.selectedForBulk.sort((a,b) => a.uuid - b.uuid)
		},
		variationEtags(variation) {
			return this.$store.state.etags.filter(etag => {
				return etag.property.includes('unitID=' + variation.uuid)
			})
		},
		doBulk() {
			this.bulkEnabled = !this.bulkEnabled
			this.variations.forEach(variation => {
				const etags = this.variationEtags(variation)
				if(etags && etags.length) {
					variation.selectedForBulk = this.bulkEnabled
					if(variation.selectedForBulk) {
						this.selectedForBulk.push(variation)
					} else {
						const index = this.selectedForBulk.indexOf(variation)
						this.selectedForBulk.splice(index, 1)
					}
				}
			})
		},
	},
	computed: {
		checkboxIsIndeterminate() {
			return !!(this.selectedForBulk.length && this.selectedForBulk.length < this.variations.length)
		},
		amountSelectedForBulk() {
			return this.selectedForBulk.length
		},
		zones() {
			return this.$store.state.zones
		},
		currentZoneName() {
			return this.zones.filter(zone => zone.uuid === this.zone)[0].name
		},
		zoneHasEtags() {
			let hasEtags = false

			this.variations.forEach(variation => {
				const etags = this.variationEtags(variation)
				if(etags && etags.length) {
					hasEtags = true
				}
			})

			return hasEtags
		},
		textSelectedForBulk() {
			if (this.amountSelectedForBulk < 1) {
				return this.$t('noItemsSelected')
			} else if (this.amountSelectedForBulk === this.variations.length) {
        return this.$t('allNumberItemsSelected', {number: this.amountSelectedForBulk})
			} else {
				return `<strong>${this.amountSelectedForBulk}</strong> ${this.amountSelectedForBulk === 1 ? this.$t('itemSelected') : this.$t('itemsSelected')}`
			}
		}
	}
}
</script>
