<template>
  <v-dialog
    max-width="400"
    value="true"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>{{ $t('etags') }}</v-card-title>
      <v-card-subtitle>{{ $t('selectAnEtagToChange') }}</v-card-subtitle>
      <EtagList
        :etags="etags"
        @selected="selected"
      />
    </v-card>
  </v-dialog>
</template>

<script>

import EtagList from "../components/EtagList"

export default {
    name: 'EtagsDialog',
    props: {
      etags: {
        type: Array, 
        default: () => []
      }
    },
    components: {
        EtagList
    },
    methods: {
        selected(etag) {
            this.$emit('selected', etag)
        },
        close() {
            this.$emit('closed', true)
        }
    }
}
</script>