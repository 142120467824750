<template>
  <v-dialog
    :value="value"
    max-width="550px"
    @click:outside="closed"
  >
    <v-card
      style="position: relative"
      class="pa-4 pt-6"
    >
      <v-card-title class="pa-2">
        {{ $t('editTable') }}
      </v-card-title>
      <v-row justify="center">
        <v-col cols="8">
          <v-text-field
            hide-details
            :disabled="false"
            type="text"
            :label="$t('label')"
            v-model="selectedVariation.label"
            outlined
            x-large
          />
        </v-col>
        <v-col cols="4">
          <v-switch
            hide-details
            :disabled="false"
            :label="selectedVariation.status ? $t('active') : $t('disabled')"
            v-model="selectedVariation.status"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            hide-details
            :disabled="false"
            type="text"
            :label="$t('xCoordinates')"
            v-model="selectedVariation.x"
            outlined
            x-large
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            hide-details
            :disabled="false"
            type="text"
            :label="$t('yCoordinates')"
            v-model="selectedVariation.y"
            outlined
            x-large
          />
        </v-col>
        <v-col cols="12">
          <v-select
            :disabled="false"
            :label="$t('zone')"
            :items="zones"
            item-value="uuid"
            item-text="name"
            v-model="selectedVariation.zone"
            outlined
            x-large
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            hide-details
            :disabled="false"
            type="text"
            :label="$t('minimumPersons')"
            v-model="selectedVariation.minimumPersons"
            outlined
            x-large
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            hide-details
            :disabled="false"
            type="text"
            :label="$t('maximumPersons')"
            v-model="selectedVariation.maximumPersons"
            outlined
            x-large
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            hide-details
            :disabled="false"
            type="text"
            :label="$t('standardPersons')"
            v-model="selectedVariation.standardPersons"
            outlined
            x-large
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            hide-details
            :disabled="false"
            type="text"
            :label="$t('sortingWeight')"
            v-model="selectedVariation.sorting_weight"
            outlined
            x-large
          />
        </v-col>
      </v-row>
      <v-row v-if="error">
        <v-col>
          <v-alert type="error">
            {{ $t('somethingWentWrongWhileUpdatingThisUnit') }}
          </v-alert>
        </v-col>
      </v-row>
      <v-card-actions class="pa-0">
        <v-row>
          <v-col cols="6">
            <v-btn
              block
              color="error"
              @click="closed"
              :disabled="false"
              large
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              v-if="mode === 'create' || variation === null || variation === undefined"
              block
              color="primary"
              @click="createLocationVariation"
              :disabled="loading"
              :loading="loading"
              large
            >
              {{ $t('create') }}
            </v-btn>
            <v-btn
              v-else
              block
              color="primary"
              @click="updateLocationVariation"
              :disabled="loading"
              :loading="loading"
              large
            >
              {{ $t('update') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "LocationVariationDialog",
    props: {
      value: {
        type: Boolean,
        required: true
      },
      variation: {
        type: Object,
        required: false,
        default: () => {}
      },
      locationUUID: {
        type: String,
        required: true
      },
      mode: {
        type: String,
        required: false,
        default: 'create'
      }
    },
    data: () => ({
      selectedVariation: {
        zone: '',
        location: '',
        status: true,
				sorting_weight: 1
      },
      loading: false,
      error: false
    }),
    computed: {
      zones() {
        return this.$store.state.zones
      }
    },
    methods: {
      closed() {
        this.$emit('closed')
      },
      updateLocationVariation() {
        this.loading = true

        console.log(parseFloat(this.selectedVariation.x))

        if (!isNaN(parseFloat(this.selectedVariation.x))) {
          this.selectedVariation.x = parseFloat(this.selectedVariation.x)
        }
        if (!isNaN(parseFloat(this.selectedVariation.y))) {
          this.selectedVariation.y = parseFloat(this.selectedVariation.y)
        }
        if (!isNaN(parseInt(this.selectedVariation.minimumPersons))) {
          this.selectedVariation.minimumPersons = parseInt(this.selectedVariation.minimumPersons)
        }
        if (!isNaN(parseInt(this.selectedVariation.maximumPersons))) {
          this.selectedVariation.maximumPersons = parseInt(this.selectedVariation.maximumPersons)
        }

        if (!isNaN(parseInt(this.selectedVariation.standardPersons))) {
          this.selectedVariation.standardPersons = parseInt(this.selectedVariation.standardPersons)
        }
        if (!isNaN(parseInt(this.selectedVariation.sorting_weight))) {
          this.selectedVariation.sorting_weight = parseInt(this.selectedVariation.sorting_weight)
        }

        delete this.selectedVariation.w
        delete this.selectedVariation.h
        delete this.selectedVariation.i
        delete this.selectedVariation.moved

        this.$store.dispatch('updateLocationVariation', {locationVariation: this.selectedVariation}).then(res => {
          if(res !== null) {

            this.$store.commit('updateActionSuccess', {
							message: this.$t('success')
						})

            this.$store.dispatch('getLocations')
            this.closed()

          } else {

            this.$store.commit('updateActionError', {
              message: this.$t('errorOccurred'),
              subMessage: this.$t('unknownErrorPleaseTryAgainIfErrorPersistsPleaseContactSupport')
            })

            this.error = true
          }
        }).finally(() => {
          this.loading = false
        })
      },
      createLocationVariation() {
        this.loading = true
        if (!isNaN(parseFloat(this.selectedVariation.x))) {
          this.selectedVariation.x = parseFloat(this.selectedVariation.x)
        }
        if (!isNaN(parseFloat(this.selectedVariation.y))) {
          this.selectedVariation.y = parseFloat(this.selectedVariation.y)
        }
        if (!isNaN(parseInt(this.selectedVariation.minimumPersons))) {
          this.selectedVariation.minimumPersons = parseInt(this.selectedVariation.minimumPersons)
        }
        if (!isNaN(parseInt(this.selectedVariation.maximumPersons))) {
          this.selectedVariation.maximumPersons = parseInt(this.selectedVariation.maximumPersons)
        }

        if (!isNaN(parseInt(this.selectedVariation.standardPersons))) {
          this.selectedVariation.standardPersons = parseInt(this.selectedVariation.standardPersons)
        }
        if (!isNaN(parseInt(this.selectedVariation.sorting_weight))) {
          this.selectedVariation.sorting_weight = parseInt(this.selectedVariation.sorting_weight)
        }
        
        this.$store.dispatch('createLocationVariation', {locationVariation: this.selectedVariation}).then(res => {
          if(res !== null) {
            this.$store.commit('updateActionSuccess', {
							message: this.$t('success')
						})
            this.closed()
          } else {
            this.$store.commit('updateActionError', {
              message: this.$t('errorOccurred'),
              subMessage: this.$t('unknownErrorPleaseTryAgainIfErrorPersistsPleaseContactSupport')
            })
            this.error = true
          }
        }).finally(() => {
          this.loading = false
        })
      }
    },
    mounted() {
      if(this.variation !== null && typeof this.variation !== 'undefined') {
        this.selectedVariation = JSON.parse(JSON.stringify(this.variation))

        if(this.variation.zone !== null && typeof this.variation.zone !== 'undefined' && this.variation.zone.uuid !== null && typeof this.variation.zone.uuid !== 'undefined') {
          delete this.selectedVariation.zone
          this.selectedVariation.zone = this.variation.zone.uuid
        }
      }

      this.selectedVariation.location = this.locationUUID
    }
  }
</script>

<style scoped>

</style>
