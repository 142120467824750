<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn :disabled="processing" :loading="processing" icon @click="releaseItemBlock">
        <v-icon color="red">mdi-close-circle</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
	name: 'BlockedItemListItem',
	props: ['itemBlock'],
	data() {
		return {
			processing: false
		}
	},
	computed: {
		item() {
			return this.$store.state.items.find(item => item.uuid === this.itemBlock.item)
		}
	},
	methods: {
		releaseItemBlock() {
			this.processing = true
			this.$store.dispatch('releaseItemBlock', this.itemBlock).then(isSuccess => {
				if(isSuccess) {
					this.$emit('deleted')
				}
			}).finally(() => {
				this.processing = false
			})
		}
	}
}
</script>
