<template>
  <div>
    <v-list v-if="itemBlocks != null">
      <template v-for="(itemBlock, index) in itemBlocks">
        <BlockedItemListItem
          :key="index"
          :item-block="itemBlock"
          @deleted="getItemBlocks"
        />
        <v-divider :key="index + '-divider'" />
      </template>
    </v-list>
  </div>
</template>

<script>

import BlockedItemListItem from '@/components/item/BlockedItemListItem'

export default {
	name: 'BlockedItems',
	props: ['location'],
	components: {
		BlockedItemListItem
	},
	data() {
		return {
			itemBlocks: null
		}
	},
	methods: {
		getItemBlocks() {
			return this.$store.dispatch('getItemLocationBlocks', this.location).then(itemBlocks => {
				this.itemBlocks = itemBlocks
			}).catch(() => {

			})
		}
	},
	mounted() {
		this.getItemBlocks()
	}
}
</script>
