<template>
  <v-dialog
    max-width="800"
    value="true"
    @click:outside="clickOutside"
    :persistent="processing"
    scrollable
  >
    <v-card>
      <v-card-title>{{ $t('eTags-bulkAction') }}</v-card-title>
      <v-card-subtitle>{{ add ? this.$t('addMultipleETags') : this.$t('updateMultipleETags') }}</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col>
            <v-expansion-panels v-if="add">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ selectedItemsText }} - {{ tagsToBeClaimed }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense>
                    <v-col
                      cols="12"
                      md="6"
                      v-for="(item, i) in selectedItems"
                      :key="i"
                    >
                      <v-row
                        dense
                        align="center"
                      >
                        <v-col cols="12">
                          <span>{{ item.label }}</span>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            label="eTag"
                            class="mb-5"
                            outlined
                            hide-details
                            v-model="etagValues[item.uuid]"
                            :items="etags(etagValues[item.uuid])"
                            :filter="etagFilter"
                            item-text="tagID"
                            item-value="tagID"
                            :no-data-text="$t('noETagsAreAvailableAtTheMoment')"
                          >
                            <template v-slot:selection="data">
                              <div class="my-1">
                                <div :class="data.item.description ? 'pb-1' : ''">
                                  {{ data.item.tagID }}
                                </div>
                                <div style="font-size: 12px;">
                                  {{ data.item.description }}
                                </div>
                              </div>
                            </template>
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.tagID" />
                                <v-list-item-subtitle v-html="data.item.description" />
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels v-if="!add">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ selectedItemsText }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels
                    multiple
                    focusable
                  >
                    <v-row>
                      <v-col
                        v-for="(item, i) in selectedItems"
                        :key="i"
                        cols="12"
                        sm="6"
                        md="4"
                        class="sub-expansion"
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header>{{ item.label }} ({{ variationEtags(item).length }} {{ $t('selected') }}) </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-list class="py-0">
                              <template v-for="(etag, index) in variationEtags(item)">
                                <v-list-item :key="etag.tagID">
                                  <v-list-item-title class="caption">
                                    {{ etag.tagID }}
                                  </v-list-item-title>
                                </v-list-item>
                                <v-divider
                                  :key="'e-'+index"
                                  v-if="index < variationEtags(item).length"
                                />
                              </template>
                            </v-list>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-col>
                    </v-row>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row align="top">
          <v-col
            cols="12"
            md="6"
          >
            <EtagForm
              class="pl-6 pr-6"
              v-model="temporaryEtag"
              bulk
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <span>{{ $t('preview') }}</span>
            <div
              class="mr-5"
              :class="$vuetify.breakpoint.smAndDown ? 'ml-5' : ''"
            >
              <iframe
                :title="$t('eTagPreview')"
                :src="propertyURL"
                width="100%"
                style="border: 1px solid #ccc"
                height="600px"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-0">
        <v-row
          dense
          align="center"
          justify="space-between"
        >
          <v-col>
            <v-row dense>
              <v-col
                cols="12"
                md="auto"
              >
                <v-btn
                  color="error"
                  large
                  @click="clickOutside"
                  :disabled="processing"
                >
                  {{ $t('back') }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="auto"
                v-if="!add"
              >
                <v-btn
                  text
                  color="error"
                  large
                  :disabled="processing"
                  @click="showConfirmDelete = !showConfirmDelete"
                  :loading="deleting"
                >
                  {{ $t('delete') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              color="primary"
              large
              :disabled="processing || !canCreate"
              :loading="creating"
              v-if="add"
              @click="create"
            >
              {{ $t('createMultiple') }}
            </v-btn>
            <v-btn
              color="primary"
              large
              :disabled="processing"
              :loading="updating"
              v-else
              @click="update"
            >
              {{ $t('updateSelected') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-dialog
      max-width="500"
      v-model="showConfirmDelete"
      v-if="showConfirmDelete"
    >
      <v-card>
        <v-card-title>
          {{ $t('areYouSure?') }}
        </v-card-title>
        <v-card-text class="text-justify pb-0 pt-2">
          <v-alert>{{ $t('pleaseConfirmThisActionYouAreAboutToRemoveAllETagsFrom', {itemsLength: this.selectedItems.length }) }} {{ this.selectedItems.length === 1 ? this.$t('locationLC') : this.$t('locationsLC') }}.</v-alert>
          <v-alert type="info">
            {{ $t('noteThatAllETagsAndTheirSettingsWillBeRemovedAndWillNotBeRecoverable') }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-row
            dense
            justify="space-between"
          >
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                block
                color="primary"
                large
                @click="showConfirmDelete = !showConfirmDelete"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                block
                color="error"
                large
                @click="remove"
              >
                {{ $t('confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import EtagForm from "@/components/EtagForm"
export default {
	name: "EtagBulkDialog",
	components: {
		EtagForm
	},
	props: {
		selectedItems: {
      type: Array,
      default: () => []
    },
		location: {
      type: Object,
      default: () => {}
    },
		add: {
      type: Boolean
    }
	},
	data() {
		return {
			op: null,
			temporaryEtag: {
				tagID: null,
				property: null,
			},
			processing: false,
			deleting: false,
			creating: false,
			updating: false,
			notReady: true,
			showConfirmDelete: false,
			etagValues: {},
			etagsToHide: [],
			etagsToChooseFrom: []
		}
	},
	watch: {
	},
	methods: {
		selectedEtag() {
			this.etagsToHide = Object.values(this.etagValues).filter(i => i)
		},
		variationEtags(variation) {
			return this.$store.state.etags.filter(etag => {
				return etag.property.includes('unitID=' + variation.uuid)
			})
		},
		clickOutside() {
			if(!this.processing) {
				this.$emit('closed')
			}
		},
		remove() {
			this.showConfirmDelete = false
			this.processing = true
			this.deleting = true

			let tagsToRelease = []

			this.selectedItems.forEach(item => {
				this.variationEtags(item).forEach(etag => {
					const releaseAction = this.$store.dispatch('releaseEtag', etag)
					tagsToRelease.push(releaseAction)
				})
			})

			return Promise.all(tagsToRelease).then(results => {
				let unsuccessfulResults = results.filter(result => !result)
				let successfulResults = results.length - unsuccessfulResults.length
				this.$emit('bulkReleased', { unsuccessfulResults: unsuccessfulResults,  successfulResults: successfulResults} )
			}).finally(() => {
				this.processing = false
				this.deleting = false
			})
		},
		create() {
			this.processing = true
			this.creating = true

			let tagsToBeClaimed = []
			let newURL = new URL(this.temporaryEtag.property)

			this.selectedItems.forEach(item => {
				let tagID = this.etagValues[item.uuid]
				let etag = this.unclaimedEtags.filter(etag => etag.tagID === tagID).slice()[0]
				etag.organization = this.organizationUUID
				etag.location = this.location
				etag.unitID = item.uuid
				newURL.searchParams.set('unitID', item.uuid)
				etag.property = newURL.toString()
				const claimAction = this.$store.dispatch('claimEtag', etag)
				tagsToBeClaimed.push(claimAction)
			})

			return Promise.all(tagsToBeClaimed).then(results => {
				let unsuccessfulResults = results.filter(result => !result.tagID)
				let successfulResults = results.length - unsuccessfulResults.length
				this.$emit('bulkClaimed', { unsuccessfulResults: unsuccessfulResults,  successfulResults: successfulResults} )
			}).finally(() => {
				this.processing = false
				this.creating = false
			})
		},
		update() {
			this.processing = true
			this.updating = true

			let tagsToBeUpdated = []
			let updatedURL = new URL(this.temporaryEtag.property)
			this.selectedItems.forEach(item => {
				this.variationEtags(item).forEach(etag => {
					updatedURL.searchParams.set('unitID', item.uuid)
					etag.property = updatedURL.toString()
					const updateAction = this.$store.dispatch('updateEtag', etag)
					tagsToBeUpdated.push(updateAction)
				})
			})

			return Promise.all(tagsToBeUpdated).then(results => {
				let unsuccessfulResults = results.filter(result => !result.uuid)
				let successfulResults = results.length - unsuccessfulResults.length
				this.$emit('bulkUpdated', { unsuccessfulResults: unsuccessfulResults,  successfulResults: successfulResults} )
			}).finally(() => {
				this.processing = false
				this.updating = false
			})
		},
		etags(etagValue) {
			//return this.$store.state.unclaimedEtags
			let etags = this.$store.state.unclaimedEtags
			let values = Object.values(this.etagValues).filter(i => i)
			return etags.filter(etag => !values.some(e => e !== etagValue && e === etag.tagID))
		},
		etagFilter (item, queryText) {
			const searchText = queryText ? queryText.toLowerCase() : queryText
			const tagID = item.tagID ? item.tagID.toLowerCase() : item.tagID
			const description = item.description ? item.description.toLowerCase() : item.description

			if(description && tagID) {
				return description.indexOf(searchText) > -1 || tagID.indexOf(searchText) > -1
			}

			if(description) {
				return description.indexOf(searchText) > -1
			}

			if(tagID) {
				return tagID.indexOf(searchText) > -1
			}

			return false
		}
	},
	computed: {
		canCreate() {
			return this.selectedEtags.length === this.selectedItems.length
		},
		organizationUUID() {
			return this.$store.state.organization.uuid
		},
		selectedEtags() {
			return Object.values(this.etagValues)
			// let values = []
			// for(let i in this.etagValues) {
			// 	values.push(this.etagValues[i])
			// }
			// return values
			//return this.selectedItems.map(item => item.selectedEtag).filter(i => i)
		},
		selectedItemsText() {
			if(!this.selectedItems || !this.selectedItems.length) {
        return this.$t('noItemsSelected')
			} else if(this.selectedItems.length === 1) {
        return this.$t('1ItemSelected')
			} else {
				return this.selectedItems.length + ' ' + this.$t('itemsSelected')
			}
		},
		tagsToBeClaimed() {
			if(!this.selectedEtags || !this.selectedEtags.length) {
				return this.$t('noTagsToBeClaimed')
			} else if(this.selectedEtags.length === 1) {
				return this.$t('1TagToBeClaimed')
			} else {
				return this.selectedEtags.length + ' ' + this.$t('tagsToBeClaimed')
			}
		},
		unclaimedEtags() {
			return this.$store.state.unclaimedEtags
		},
		propertyURL() {
			return this.temporaryEtag.property
		},
		firstSelected() {
			if(this.selectedItems && this.selectedItems.length) {
				return this.selectedItems[0]
			} else {
				return null
			}
		}
	},
	beforeMount() {
		this.op = this.add ? 'create' : 'update'

		this.temporaryEtag.property = 'https://items.salescloud.is?organizationUUID=' + this.organizationUUID + '&l=' + this.location.uuid + '&unitID=' + (this.firstSelected ? this.firstSelected.uuid : '')
		/* adding default values */
		this.temporaryEtag.property += '&singlePageCategories=true'

		if(this.selectedItems && this.selectedItems.length) {
			let etags = this.variationEtags(this.selectedItems[0])
			if(etags && etags.length) {
				this.temporaryEtag = etags.slice()[0]
			}
		}

		// if(this.add) {
		// 	this.selectedItems.forEach(item => {
		// 		this.etagValues[item.uuid] = null
		// 	})
		// }
	}
}
</script>

<style scoped>
.sub-expansion >>> .v-expansion-panel-content__wrap {
	padding: 0;
}
</style>
