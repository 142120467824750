<template>
  <v-card
    class="pa-4 pt-6"
  >
    <div>
      <v-card-title class="pa-2">
        {{ $t('editCombination') }}
      </v-card-title>
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field
            hide-details
            disabled
            readonly
            type="text"
            :label="$t('label')"
            v-model="storedCombination.label"
            outlined
            x-large
          />
        </v-col>
        <v-col cols="12">
          <v-select
            :disabled="storedCombination.location !== null && typeof storedCombination.location !== 'undefined'"
            :label="$t('location')"
            :items="locations"
            item-text="label"
            return-object
            v-model="storedCombination.location"
            outlined
            hide-details
            x-large
          />
        </v-col>
        <v-col cols="12">
          <v-select
            :disabled="false"
            :label="$t('tables')"
            :items="locationVariations"
            item-text="label"
            return-object
            multiple
            @change="locationVariationSelected"
            v-model="storedCombination.locationVariations"
            outlined
            hide-details
            x-large
          />
        </v-col>
        <v-col
          v-if="mode !== 'create' && storedCombination.uuid !== null && typeof storedCombination.uuid !== 'undefined'"
          cols="12"
        >
          <v-switch
            hide-details
            :disabled="false"
            :label="storedCombination.active ? $t('active') : $t('disabled')"
            v-model="storedCombination.active"
          />
        </v-col>
        <v-col
          v-if="mode === 'create' || storedCombination.uuid === null || typeof storedCombination.uuid === 'undefined'"
          cols="12"
        >
          <v-checkbox
            :disabled="false"
            :label="$t('createSubCombinations')"
            v-model="createSubCombinations"
            :hint="$t('createSubCombinationsHint')"
            persistent-hint
          />
        </v-col>
      </v-row>
      <v-row v-if="errorMessage !== null">
        <v-col class="py-0">
          <v-alert type="error">
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>
      <v-card-actions class="pa-0">
        <v-row>
          <v-col cols="6">
            <v-btn
              block
              color="error"
              @click="close"
              :disabled="false"
              large
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              v-if="mode === 'create' || storedCombination.uuid === null || typeof storedCombination.uuid === 'undefined'"
              block
              color="primary"
              @click="createLocationVariationCombination"
              :disabled="loading"
              :loading="loading"
              large
            >
              {{ $t('create') }}
            </v-btn>
            <v-btn
              v-else
              block
              color="primary"
              @click="updateLocationVariationCombination"
              :disabled="loading"
              :loading="loading"
              large
            >
              {{ $t('create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: "EditLocationVariationCombination",
    components: {},
    props: {
      combination: {
        type: Object,
        required: false,
        default: null
      },
      location: {
        type: Object,
        required: false,
        default: null
      },
      mode: {
        type: String,
        default: 'edit'
      }
    },
    data: () => ({
      storedCombination: {
        label: '',
        active: true,
        location: null,
        locationVariations: []
      },
      createSubCombinations: false,
      loading: false,
      errorMessage: null
    }),
    methods: {
      close() {
        this.storedCombination = {
          label: '',
          active: true,
          location: this.location,
          locationVariations: []
        }
        this.$emit('close')
      },
      locationVariationSelected(newValue) {
        if(this.labelIsDynamic === true) {
          this.storedCombination.label = newValue.reduce((prev, v) => {
            return prev !== '' ? prev + ' + ' + v.label : v.label
          }, '')
        }
      },
      createLocationVariationCombination() {
        if(this.storedCombination.label.length < 1 || this.storedCombination.location === null || typeof this.storedCombination.location === 'undefined' || this.storedCombination.location.uuid === null || typeof this.storedCombination.location.uuid === 'undefined') {
          this.errorMessage = null
          return
        }

        if(this.createSubCombinations) {
          let combinations = []

          if(Array.isArray(this.storedCombination.locationVariations)) {


            for(let i = 0; i < this.storedCombination.locationVariations.length; i++) {
              for(let batchSize = 2; batchSize <= this.storedCombination.locationVariations.length; batchSize++) {
                const combination = {
                  label: '',
                  location: this.storedCombination.location.uuid,
                  locationVariations: []
                }

                for (let c = 0; c < batchSize; c++) {
                  const index = this.locationVariations.findIndex(v => {
                    return this.storedCombination.locationVariations[i + c] && v.uuid === this.storedCombination.locationVariations[i + c].uuid
                  })
                  if (index !== -1) {
                    combination.label = combination.label !== '' ? combination.label + ' + ' + this.locationVariations[index].label : this.locationVariations[index].label
                    combination.locationVariations.push('' + this.storedCombination.locationVariations[i + c].uuid)
                  }
                }

                if (combination.locationVariations.length === batchSize) {
                  combinations.push(combination)
                }
              }
            }
          }

          this.$store.dispatch('createMultipleLocationVariationCombinations', { combinations }).then(res => {
            if(Array.isArray(res)) {
              this.$store.commit('updateActionSuccess', {
                message: this.$t('success'),
                subMessage: this.$t('combinationsHaveBeenSuccessfullyCreated')
              })
              this.close()
            } else {
              this.errorMessage = this.$t('unexpectedErrorCreatingCombinationsFailed')
            }
          })
        } else {
          const combination = {
            label: this.storedCombination.label,
            location: this.storedCombination.location.uuid,
            active: this.storedCombination.active,
            locationVariations: []
          }

          if(Array.isArray(this.storedCombination.locationVariations)) {
            for(let i = 0; i < this.storedCombination.locationVariations.length; i++) {
              if(this.locationVariations.findIndex(v => v.uuid === this.storedCombination.locationVariations[i].uuid) !== -1) {
                combination.locationVariations.push( '' + this.storedCombination.locationVariations[i].uuid )
              }
            }
          }

          this.$store.dispatch('createLocationVariationCombination', { combination }).then(res => {
            if(res !== null && typeof res !== 'undefined' && typeof res.uuid === 'string') {
              this.$store.commit('updateActionSuccess', {
                message: this.$t('success'),
                subMessage: this.$t('combinationHasBeenSuccessfullyCreated')
              })
              this.close()
            } else {
              this.errorMessage = this.$t('unexpectedErrorCreatingCombinationFailed')
            }
          })
        }
      },
      updateLocationVariationCombination() {
        if(typeof this.storedCombination.uuid !== 'string' || this.storedCombination.label.length < 1 || this.storedCombination.location === null || typeof this.storedCombination.location === 'undefined' || this.storedCombination.location.uuid === null || typeof this.storedCombination.location.uuid === 'undefined') {
          this.errorMessage = this.$t('combinationIsNotValid')
          return
        }

        const combination = {
          uuid: this.storedCombination.uuid,
          label: this.storedCombination.label,
          location: this.storedCombination.location.uuid,
          active: this.storedCombination.active,
          locationVariations: []
        }

        if(Array.isArray(this.storedCombination.locationVariations)) {
          for(let i = 0; i < this.storedCombination.locationVariations.length; i++) {
            if(this.locationVariations.findIndex(v => v.uuid === this.storedCombination.locationVariations[i].uuid) !== -1) {
              combination.locationVariations.push( '' + this.storedCombination.locationVariations[i].uuid )
            }
          }
        }

        this.$store.dispatch('updateLocationVariationCombination', { combination }).then(res => {
          if(res !== null && typeof res !== 'undefined' && typeof res.uuid === 'string') {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: this.$t('combinationHasBeenSuccessfullyUpdated')
            })
            this.close()
          } else {
            this.errorMessage = this.$t('unexpectedErrorUpdatingCombinationFailed')
          }
        })
      }
    },
    computed: {
      locations() {
        return this.$store.state.locations
      },
      locationVariations() {
        if(this.storedCombination.location !== null && typeof this.storedCombination.location !== 'undefined' && this.storedCombination.location.uuid !== null && typeof this.storedCombination.location.uuid !== 'undefined') {
          const selectedLocation = this.locations.find(l => l.uuid === this.storedCombination.location.uuid)
          return selectedLocation.variations
        }
        return []
      },
      labelIsDynamic() {
        const labelArray  = this.storedCombination.label.split(' + ')
        if(this.storedCombination.label === '') {
          return true
        }
        for(let i = 0; i < labelArray.length; i++) {
          const arrIndex = this.locationVariations.findIndex(v =>  v.label === labelArray[i] )
          if(arrIndex === -1) {
            return false
          }
        }
        return true
      }
    },
    watch: {
      combination(newValue) {
        if(newValue !== null && typeof newValue !== 'undefined') {
          this.storedCombination = JSON.parse(JSON.stringify(newValue))
        } else {
          this.storedCombination = {
            label: '',
            active: true,
            location: this.location,
            locationVariations: []
          }
        }
      }
    },
    mounted() {
      if(this.combination !== null && typeof this.combination !== 'undefined') {
        this.storedCombination = JSON.parse(JSON.stringify(this.combination))
      } else {
        this.storedCombination = {
          label: '',
          active: true,
          location: this.location,
          locationVariations: []
        }
      }
    }
  }
</script>

<style scoped>

</style>
