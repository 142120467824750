<template>
  <div>
    <v-row>
      <v-col>
        <LocationInformation
          class="mb-3"
          :location="locationObject"
          @updateLocationValue="updateLocationValue"
        />
        <v-btn
          block
          :loading="loading"
          :disabled="loading"
          color="primary"
          x-large
          @click="saveLocation(locationObject)"
          style="margin-bottom: 100px;"
        >
          {{ $t('save') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
	import LocationInformation from "./LocationInformation"

	export default {
		name: 'LocationForm',
		components: {
			LocationInformation
		},
		props: {
      location: {
        type: Object,
        default: () => {}
      }
    },
		data() {
			return {
				loading: false,
        locationObject: null
			}
		},
		methods: {
      updateLocationValue({ key, value }) {
        this.locationObject[key] = value
      },
			saveLocation(location) {
        this.loading = true

        if(location.maximumSalesPerSlot) {
          location.maximumSalesPerSlot = parseInt(location.maximumSalesPerSlot)
        }

        if(location.intervalBetweenSlotsInMillis) {
          location.intervalBetweenSlotsInMillis = parseInt(location.intervalBetweenSlotsInMillis)
        }

        if(location.pauseBetweenSlotsInMillis) {
          location.pauseBetweenSlotsInMillis = parseInt(location.pauseBetweenSlotsInMillis)
        }

        this.$store.dispatch('updateLocation', {location}).then(res => {
          if(res !== null) {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: this.$t('locationSuccessfullyUpdated')
            })
          }
				}).finally(() => {
          this.loading = false
        })
			},
		},
		computed: {
		},
		beforeMount() {
      if(typeof this.location !== 'undefined' && this.location !== null) {
        try {
          this.locationObject = JSON.parse(JSON.stringify(this.location))
        } catch (e) {
          //
        }
        if(this.location.ept) {
          this.originalEPT = this.location.ept
        }
      }
		},
		destroyed() {
      this.locationObject = null
		},
		watch: {
		}
	}
</script>

<style>

</style>
