<template>
  <div>
    <v-list-item :key="renderKey">
      <EtagDialog
        v-if="etagDialog"
        v-model="etag"
        @closed="etagDialogClosed"
        @released="released"
        @claimed="claimed"
        @updated="updated"
        :location="location"
        @updateProperty="updateProperty"
      />
      <EtagsDialog
        v-if="etagsDialog"
        :etags="etags"
        @selected="editEtag"
        @closed="etagsDialogClosed"
      />
      <v-list-item-action class="pr-8 mr-0">
        <v-checkbox
          :value="bulkSelected"
          @click="selectForBulk"
        />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ variation.label }} 
          <v-chip class="ml-2" v-if="variation.status === false">
            {{ $t('inactive')}}
          </v-chip>
        </v-list-item-title>


        <v-list-item-subtitle v-if="variation.minimumPersons || variation.maximumPersons || variation.standardPersons">
          <span v-if="variation.minimumPersons">{{ $t('min') }}: {{ variation.minimumPersons }}</span> <span v-if="variation.maximumPersons">{{ $t('max') }}: {{ variation.maximumPersons }}</span> <span v-if="variation.standardPersons">{{ $t('standard') }}: {{ variation.standardPersons }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="linkNewTag"
        >
          <v-icon>mdi-tag-plus</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn
          :disabled="etags.length === 0"
          icon
          @click="showEtagsDialog"
        >
          <v-icon>mdi-tag</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn
          icon
          @click="editLocationVariation"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <LocationVariationDialog
      v-if="showLocationVariationDialog"
      v-model="showLocationVariationDialog"
      :variation="variation"
      mode="update"
      :location-u-u-i-d="location.uuid"
      @closed="closeLocationVariationDialog"
    />
  </div>
</template>

<script>

import EtagDialog from "./EtagDialog"
import EtagsDialog from "./EtagsDialog"
import LocationVariationDialog from "./location/LocationVariationDialog"

export default {
	name: 'LocationVariationListItem',
	props: {
		variation: {
			type: Object, 
			default: () => {}
		}, 
		location: {
			type: Object, 
			default: () => {}
		}, 
		bulkSelected: {
			type: Boolean
		}
	},
	components: {
    LocationVariationDialog,
		EtagDialog,
		EtagsDialog
	},
	data: () => ({
		etagDialog: false,
		etagsDialog: false,
		etag: {},
		defaultEtag: {
			tagID: null,
			property: null
		},
    showLocationVariationDialog: false,
    renderKey: 0
	}),
	methods: {
    updateProperty(propertyString) {
      this.etag.property = propertyString
    },
		selectForBulk() {
			this.$emit('selectForBulk', this.variation)
		},
		updated() {
			this.etagDialogClosed()
      this.$store.commit('updateActionSuccess', {
        message: this.$t('success'),
        subMessage: this.$t('etagWasUpdated')
      })
			this.$store.dispatch('getEtags')
		},
		released() {
			this.etagDialogClosed()
			this.$store.dispatch('getEtags')
		},
		claimed() {
			this.etagDialogClosed()
			this.$store.dispatch('getEtags')
		},
		linkNewTag() {
			this.showEtagDialog(JSON.parse(JSON.stringify(this.defaultEtag)))
		},
		showEtagDialog(etag) {
			this.etag = etag
			this.etagDialog = true
		},
		etagDialogClosed() {
			this.etagDialog = false
		},
		showEtagsDialog() {
			this.etagsDialog = true
		},
		editEtag(etag) {
			this.etag = etag
			if(!this.etag.unitID) {
				this.etag.unitID = this.variation.uuid
			}
			this.etagsDialog = false
			this.etagDialog = true
		},
		etagsDialogClosed() {
			this.etagsDialog = false
			this.etag = {}
		},
    editLocationVariation() {
      this.showLocationVariationDialog = true
    },
    closeLocationVariationDialog() {
      this.$store.dispatch('getLocations')
      this.renderKey = this.renderKey + 1
      this.showLocationVariationDialog = false
    },
	},
	computed: {
		etags() {
      if(this.$store.state.etags !== null && typeof this.$store.state.etags !== 'undefined') {
        return this.$store.state.etags.filter(etag => {
          return etag.property.includes('unitID=' + this.variation.uuid)
        })
      }
      return []
		},
		organizationUUID() {
			return this.$store.state.organization.uuid
		}
	},
	beforeMount() {
		this.defaultEtag.property = 'https://items.salescloud.is?organizationUUID=' + this.organizationUUID + '&channel=' + '&l=' + this.location.uuid + '&unitID=' + this.variation.uuid
		/* adding default values */
		this.defaultEtag.property += '&singlePageCategories=true'
		this.defaultEtag.unitID = this.variation.uuid
		this.defaultEtag.location = this.location.uuid
		this.defaultEtag.organization = this.organizationUUID
	}
}
</script>
