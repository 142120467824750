<template>
  <v-list class="pt-0 mt-0">
    <template v-for="etag in etags">
      <EtagListItem
        class="pl-6 pr-6"
        :key="etag.tagID"
        :etag="etag"
        @selected="selected"
      />
    </template>
  </v-list>
</template>

<script>

import EtagListItem from "../components/EtagListItem"

export default {
    name: 'EtagList',
    props: {
      etags: {
        type: Array,
        default: () => []
      }
    },
    components: {
        EtagListItem
    },
    methods: {
        selected(etag) {
            this.$emit('selected', etag)
        }
    }
}
</script>